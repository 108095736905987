import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CampaignImageModel } from 'src/app/modules/delivery/components/campaign-image-model/campaign-image-model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { CustomAudienceService } from 'src/app/services/custom-audience.service';
import { DashboardControlService } from 'src/app/services/dashboard-control.service';

@Component({
  selector: 'app-table',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html',
})
export class TableComponent implements OnInit {
  isDisabled: boolean = true
  @Input() displayedColumns: any;
  @Input() pageSizes = [14, 25, 100, 1000];
  @Input()
  set dataSource(data: any) {
    if (data) {
      this._dataSource = data;
      this.dataSource.paginator = this.paginator;

      this.dataSource.sortingDataAccessor = (item: any, property: string) => {
        switch (property) {
          default:
            return item[property]?.codeName || item[property];
        }
      };
      this.dataSource.sort = this.sort;
    }
  }
  get dataSource() {
    return this._dataSource;
  }


  @Input() hideAction: boolean = false;
  @Input() hidePagination: boolean = false;
  @Input() slider: boolean = false;
  @Input() wrap: boolean = false;
  @Input() progressBar: boolean = false;
  @Input() checkboxTable: boolean = false;
  @Output() clickOnTableRow = new EventEmitter();
  @Output() clickDeleteOrder = new EventEmitter();
  @Output() clickUpdateCampaign = new EventEmitter();
  @Output() clickUpdateOrder = new EventEmitter();
  @Output() removeBannerPlacement = new EventEmitter();
  @Output() removeUserFromAllTenant = new EventEmitter();

  @Input() showEditIcon: boolean;
  @Input() showDeleteIcon: boolean;

  @Input() showRemoveIcon: boolean;
  @Input() isActive: boolean = false;

  @Input() deeplinkImgColumn: string = "";
  @Output() redirectTo = new EventEmitter();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  private _dataSource = new MatTableDataSource();
  constructor(private router: Router, public dialog: MatDialog, public customAudience: CustomAudienceService, public dashboardControlService: DashboardControlService, private custom: CustomAudienceService) {
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  rowClicked(actionName: any, row: any) {
    const actionTakenData = {
      event: actionName,
      item: row
    }
    this.clickOnTableRow.emit(actionTakenData);
  }
  deleteOrder(item: any, rowIndex = -1) {
    this.clickDeleteOrder.emit({ item: item, rowIndex: rowIndex });
    this.dashboardControlService.isDelete = true;
  }
  updateCampaign(item: any, rowIndex = -1) {
    this.clickUpdateCampaign.emit({ item: item, rowIndex: rowIndex });
  }
  updateOrder(item: any, rowIndex = -1) {
    this.clickUpdateOrder.emit({ item: item, rowIndex: rowIndex });
  }
  removeBanner(item: any, rowIndex = -1) {
    this.removeBannerPlacement.emit({ item: item, rowIndex: rowIndex });
  }

  removeUserFromTenant(item: any, rowIndex = -1) {

    this.removeUserFromAllTenant.emit({ item: item, rowIndex: rowIndex });
  }
  removeSelectedCsvFiles(item: any) {
    this.custom.deleteCsvFiles({ fileName: item.fileName, id: item.customId }).subscribe((res: any) => {
      this.removeItemFromDataSource(item);
    }, (err) => {
    })
  }

  uploadProcess(item: any) {
    var filePath = item.fileName;
    const formData = this.customAudience._roleFormData || null;
    if (formData && formData.data && formData.data.file_path) {
      var AllFiles: any = JSON.parse(formData.data.file_path);
      console.log('item', item);
      AllFiles.map((file: any) => {

        const path = file.FilePath.split('/').pop();

        if (path === filePath) {
          var b = {
            "audienceId": item?.customId,
            "FilePath": file.FilePath,
            // "status": item?.status,
            "status": "Done",
            "delete": false
          };

          this.customAudience.uploadProcess(b).subscribe((r: any) => {
            this.dataSource.data.filter((x: any) => {
              if (x.fileName == filePath) {
                x.status = r.status;
              }
              return x;
            })
          });
        }
      });
    }
  }
  removeItemFromDataSource(item: any) {
    if (this.dataSource) {
      const updatedData = this.dataSource.data.filter((x: any) => x.fileName !== item.fileName);
      this.dataSource.data = updatedData;
    }
  }

  redirectToItem(event: any) {
    this.redirectTo.emit(event);
  }

  isImageUrl(value: string): boolean {
    return typeof value === 'string' &&
      (value.toLowerCase().endsWith('.png') ||
        value.toLowerCase().endsWith('.jpeg') ||
        value.toLowerCase().endsWith('.gif') ||
        value.toLowerCase().endsWith('.jpg'));

  }
  displayImg(imgUrl: string) {
    this.dialog.open(CampaignImageModel, {
      data: { imgUrl },
      width: '80%',
      height: '80%'
    });
  }

  downloadFile(filePath: any) {
    const formData = this.customAudience._roleFormData || null;

    if (formData && formData.data && formData.data.file_path) {
      var AllFiles: any = JSON.parse(formData.data.file_path);

      AllFiles.map((file: any) => {
        const path = file.FilePath.split('/').pop();
        if (path === filePath) {
          if (path) {
            const link = document.createElement('a');
            link.href = file.FilePath;
            link.download = file.FilePath;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
    }
  }


  // downloadFile(filePath: any) {
  //   const formData = this.customAudience._roleFormData || null;
  //   var AllFiles: any = formData.data.file_path.split(',');
  //   console.log(AllFiles);
  //   AllFiles.map((file: any) => {
  //     var f = JSON.parse(file);
  //     console.log(f);
  //     // let fileName = f.split('/').pop(); console.log(fileName);
  //     // // fileName = fileName.replace(/^"|"$/g, '');
  //     // if (fileName == filePath) {
  //     //   if (file) {
  //     //     const link = document.createElement('a');
  //     //     link.href = file;
  //     //     link.download = '';
  //     //     document.body.appendChild(link);
  //     //     link.click();
  //     //     document.body.removeChild(link);
  //     //   } else {
  //     //   }
  //     // }
  //   })
  // }
  ngAfterViewInit() {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }
}


