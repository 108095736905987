import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AdFormatDropdown } from 'src/app/modules/delivery/banners-detail-page/banner-create/banner-page-mapper';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { BannerService } from 'src/app/services/banner.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { OrderService } from 'src/app/services/order.service';
import { PlacementService } from 'src/app/services/placement.service';
import { DropdownMapper } from 'src/app/utilities/dropdown-mapping';
import { RoutesMenu } from 'src/app/utilities/menu';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { banneQcImageUrl } from 'src/app/utilities/url-controller';

@Component({
  selector: 'app-banners-qc',
  templateUrl: './banners-qc.component.html',
  styleUrls: ['./banners-qc.component.scss']
})
export class BannersQcComponent {
  getActiveCampaignReports_main: any;
  getActiveCampaignReports: any;
  inactiveDataSourceCloneForChannel: any;
  activeDataSourceCloneForChannel: any;
  placementList: any;
  displayedColumnsObjs: any;
  bannerInactivedataSource: any
  bannerActivedataSource: any

  inactiveDataSourceCloneForAdFormat: any;
  activeDataSourceCloneForAdFormat: any;
  bannerUrl = environment.bannerUrl;
  channelList: any = [];
  bannerdisplayedActiveColumns: string[];
  bannerdisplayedInactiveColumns: string[];
  channelFilter: string;
  adFormatFilter: any;
  adFormatList: any =
    [
      { key: '0', codeName: 'SingleImage_1500x500' },
      { key: '1', codeName: 'SingleImage_320x480' },
      { key: '2', codeName: 'SingleImage_200x200' },
      { key: '3', codeName: 'SingleImage_1440x528' },
      { key: '4', codeName: 'SingleImage_1440x720' },

      { key: '5', codeName: 'SingleImage_746x965' },
      { key: '6', codeName: 'SingleImage_1150x960' },

      { key: '7', codeName: 'Offer/Reward Banner' },
      { key: '8', codeName: 'Story Ads' },
      { key: '9', codeName: 'VideoBanner_1500x500' },
      { key: '10', codeName: 'SingleImage_1000x400' },
      { key: '11', codeName: 'SingleImage_1000x850' },
      { key: '12', codeName: 'SingleImage_Bob_1500x500' },
      { key: '13', codeName: 'SingleImage_800x1040' },
      { key: '14', codeName: 'SingleImage_1400x1050' },
      { key: '15', codeName: 'SingleImage_1200x900' },
    ]


  constructor(private router: Router, private bannerService: BannerService,
    private placementService: PlacementService,
    private campaignsService: CampaignsService,
    private orderService: OrderService,
    private dropdownMapper: DropdownMapper,
    private authGuardService: AuthGuardService,
  ) { }
  ngOnInit(): void {
    this.bannerdisplayedInactiveColumns = [
      'Banners',
      'Creation Date',
    ];
    this.bannerdisplayedActiveColumns = [
      'Live Active Banners',
      // 'Creation Date',
    ];
    forkJoin([
      this.bannerService.getBannerData(),
      this.campaignsService.getActiveCampaignReport(),
      this.bannerService.getChannelInBannerFromTenant(Number(sessionStorage.getItem('userId')))
    ]).subscribe((res: any) => {
      const bannerFormatList = AdFormatDropdown;
      const mappedInactiveDataSource: any = [];
      this.channelList = res[2];
      res[0]?.forEach((element: any) => {
        if (
          moment(element.createdOn).isAfter(moment().subtract(10, 'days'))
        ) {
          mappedInactiveDataSource.push({
            'Id': element.bannerId,
            'Banners': banneQcImageUrl(
              element?.bannerCreatives?.[0]?.imageURL?.[0]?.imageURL,
              element.accountId
            ),
            'Ad Format': element.adFormat?.toString() != "-1" ?
              this.dropdownMapper.getDropdownObjectFromCodeOrDesc(element.adFormat.toString(), bannerFormatList, 'key')?.codeName :
              " ",
            // 'Inactive Banners': `${UrlControllerUtility.host}/Banner/File?fileName=${element?.bannerCreatives[0]?.imageURL[0]?.imageURL}&accountId=${element.accountId}`,            // 'Ad Format': element.adFormat?.toString() != "-1" ?
            //   this.dropdownMapper.getDropdownObjectFromCodeOrDesc(element.adFormat.toString(), bannerFormatList, 'key')?.codeName :
            //   " ",
            'Creation Date': moment(element.createdOn).format("YYYY-MM-DD"),
            'Account': element.accountName,
            'Details': "",
            dataForEdit: element
          });
        }
      });
      this.inactiveDataSourceCloneForChannel = JSON.parse(JSON.stringify(mappedInactiveDataSource));
      this.bannerInactivedataSource = new MatTableDataSource(mappedInactiveDataSource);

      const mappedActiveDataSource: any = [];
      res[1]?.forEach((element: any) => {
        mappedActiveDataSource.push({
          'Id': element.bannerId,
          'Live Active Banners': banneQcImageUrl(
            element.bannerCreatives ? JSON.parse(element.bannerCreatives)?.[0]?.ImageURL?.[0]?.ImageURL : "",
            element.accountId
          ),
          // 'Live Active Banners': `${UrlControllerUtility.host}/Banner/File?fileName=${JSON.parse(element.bannerCreatives)?.[0]?.ImageURL?.[0]?.ImageURL}&accountId=${element.accountId}`,
          'Account': element.accountName,
          // 'Creation Date': 'NA',
          'Ad Format': element.adFormat?.toString() != "-1" ?
            this.dropdownMapper.getDropdownObjectFromCodeOrDesc(element.adFormat.toString(), bannerFormatList, 'key') :
            " ",
          'Details': "",
          dataForEdit: element
        });
      });
      this.activeDataSourceCloneForChannel = JSON.parse(JSON.stringify(mappedActiveDataSource));
      this.bannerActivedataSource = new MatTableDataSource(mappedActiveDataSource);

      // To get Dropdown below code
      const mappedDataSource: any = [];
      const arr = (res[0].concat(res[1]));
      arr.forEach((ele: any) => {
        mappedDataSource.push({
          "Preview": `${this.bannerUrl}${ele.bannerAccountId}/${ typeof(ele.bannerCreatives) === 'string' ?
            JSON.parse(ele.bannerCreatives)?.[0]?.ImageURL?.[0]?.ImageURL :
            ele.bannerCreatives?.[0]?.imageURL?.[0]?.imageURL
          }`
        });
        const bannerTargetingObj = typeof(ele.bannerCreatives) === 'string' ? JSON.parse(ele.bannerTargeting) : ele.bannerTargeting;
        // const channelValues = bannerTargetingObj[0]?.Channel?.Values || bannerTargetingObj[0]?.channel?.values || [];
        // channelValues.forEach((channelValue: string) => {
        //   this.channelList.push({ channel: channelValue });
        // });
        const bannerTargetingObjAdFormat: any = [];
        bannerTargetingObjAdFormat.forEach((adFormatValue: string) => {
          this.adFormatList.push({ codeName: adFormatValue });
        });
      });
      // this.channelList = _.uniqBy(this.channelList, 'channel');
      this.adFormatList = _.uniqBy(this.adFormatList, 'codeName');
      this.getActiveCampaignReports = mappedDataSource;
    });

  }

  applyFilterForBoth(){
    try {
      const selectedAdFormat = this.adFormatList.find((format: any) => format.key === this.adFormatFilter)?.codeName;
      const mappedInactiveDataSource = this.inactiveDataSourceCloneForChannel?.filter((element: any) =>
        element?.['Ad Format'] === selectedAdFormat &&
        element.dataForEdit?.bannerTargeting?.[0]?.channel?.values?.includes(this.channelFilter)
      );
      this.bannerInactivedataSource = new MatTableDataSource(mappedInactiveDataSource);
      const mappedActiveDataSource = this.activeDataSourceCloneForChannel?.filter((element: any) =>
        element?.['Ad Format']?.codeName === selectedAdFormat &&
        JSON.parse(element?.dataForEdit?.bannerTargeting)?.[0]?.Channel?.Values?.includes(this.channelFilter)
      );
      this.bannerActivedataSource = new MatTableDataSource(mappedActiveDataSource);
      // this.channelFilter = "";
    } catch (e) {
      console.error('Error applying ad format filter:', e);
    }
  }

  // applyFilterForChannel() {
  //   try {
  //     const mappedInactiveDataSource = this.inactiveDataSourceCloneForChannel?.filter((element: any) =>
  //       element.dataForEdit?.bannerTargeting?.[0]?.channel?.values?.includes(this.channelFilter)
  //     );
  //     this.bannerInactivedataSource = new MatTableDataSource(mappedInactiveDataSource);

  //     const mappedactiveDataSource = this.activeDataSourceCloneForChannel?.filter((element: any) =>
  //       JSON.parse(element?.dataForEdit?.bannerTargeting)?.[0]?.Channel?.Values?.includes(this.channelFilter)
  //     );
  //     this.bannerActivedataSource = new MatTableDataSource(mappedactiveDataSource);
  //     // this.adFormatFilter = null;
  //   } catch (e) {
  //     console.log(e);
  //   }

  // }

  // applyFilterForAdFormat() {
  //   try {
  //     const selectedAdFormat = this.adFormatList.find((format: any) => format.key === this.adFormatFilter)?.codeName;
  //     const mappedInactiveDataSource = this.inactiveDataSourceCloneForChannel?.filter((element: any) =>
  //       element?.['Ad Format'] === selectedAdFormat
  //     );
  //     this.bannerInactivedataSource = new MatTableDataSource(mappedInactiveDataSource);
  
  //     const mappedActiveDataSource = this.activeDataSourceCloneForChannel?.filter((element: any) =>
  //       element?.['Ad Format']?.codeName === selectedAdFormat
  //     );
  //     this.bannerActivedataSource = new MatTableDataSource(mappedActiveDataSource);
  //     // this.channelFilter = "";
  //   } catch (e) {
  //     console.error('Error applying ad format filter:', e);
  //   }
  // }
  

  redirectToOrder(event: any) {
    this.router.navigate([`${RoutesMenu.deliveryBannersDetails}/${event?.dataForEdit?.bannerId}`]);
  }
}











  // applyFilterForAdFormat() {
  //   try {
  //     const selectedAdFormat = this.adFormatList.find((format: any) => format.key === this.adFormatFilter)?.codeName;
  //     const mappedActiveDataSource = this.activeDataSourceCloneForChannel?.filter((element: any) =>
  //       element?.['Ad Format']?.codeName === selectedAdFormat
  //     );
  //     this.bannerActivedataSource = new MatTableDataSource(mappedActiveDataSource);
  //   } catch (e) {
  //     console.error('Error applying ad format filter:', e);
  //   }
  // }





















