<div class="dashboard-container">
    <mat-card>
        <div class="dashboard-head">
            <div fxLayout="row" fxLayoutAlign="space-between center">  
                <h2>Campaign Data Yesterday</h2>
                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                </mat-form-field>
            </div>
            <div class="dashboard-border">
                <mat-card style="height:80vh">
                            <div class="table-scroll-pricing">
                                <app-table class="special-table" 
                                [displayedColumns]="displayedColumnsCampaigns" 
                                [dataSource]="dataSourceCampaigns"
                                [slider]="true" 
                                [hidePagination]="true">
                                </app-table>
                            </div>
                </mat-card>
            </div>
           
        </div>
    </mat-card>
</div>