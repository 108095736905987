import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { AccountsService } from './accounts.service';
import { DashboardControlService } from './dashboard-control.service';

@Injectable({
  providedIn: 'root'
})
export class BrandBuService {
  public _brandBuFormData: any;

  constructor(private applicationApiService:ApplicationApiService, 
              private accountsService:AccountsService,
              private dashboardControlService:DashboardControlService) { }

  getBrandBuData(){
    // const queryParams ={
    //   "offset":0,
    //   "next":100,
    //   "sortingColumn":"None",
    //   "sortType":"ASC",
    // }
    const queryParams ={
      "pageNumber":1,
      "rows":1000,
      "sortingColumn":"None",
      "sortType":"ASC",
    }
    let url = '';
    if(this.accountsService._accountId && !this.dashboardControlService._isComingFromSidebar){
      url = "Brand/account/" + this.accountsService._accountId;
    }else{
      url = "Brand";
    }  
    return this.applicationApiService.getData( url ,queryParams);
  }

  getBrandById(id: string) {
    return this.applicationApiService.getData(`Brand/${id}`, "");
  } 
  getBrandAccountType(accountId:string){
    return this.applicationApiService.getData("Brand/accounttype/" + accountId,'')
  }
  getAgencyForOrder(){
    return this.applicationApiService.getData("Brand/accounttype/Agency",'')
  }
  getAdvertiserForOrder(){
    return this.applicationApiService.getData("Brand/accounttype/Advertiser",'')
  }

  saveBrandsBuAccount(body:any){
    return this.applicationApiService.postData("Brand",body);
  }
  updateBrandsBuAccount(body:any){
    return this.applicationApiService.putData("Brand",body);
  }
  deleteBrandBuAccount(id:number){
    return this.applicationApiService.deleteData("Brand/" + id);
  }
 
  get brandBuFormData(){
    return this._brandBuFormData;
   }
   set brandBuFormData(data){
    this._brandBuFormData = data;
   }
  
}
