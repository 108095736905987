import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomAudienceService {
  constructor(private applicationApiService: ApplicationApiService, private http: HttpClient) { }
  public _roleFormData: any;

  getCustomFormData() {
    return this._roleFormData;
  }

  getCustomAudience() {
    return this.applicationApiService.getData("CustomAudience", "")
  }
  getCustomAudienceById(id: number) {
    return this.applicationApiService.getData(`CustomAudience/${id}`, "");
  }

  createCustomAudience(SegmentName: any, SegmentDescription: any, file: any[]) {
    const formData = new FormData();
    formData.append('SegmentName', SegmentName);
    formData.append('SegmentDescription', SegmentDescription || '');
    if (file) {
      file.forEach(file => {
        formData.append('files', file);
      });
    }
    return this.applicationApiService.postDataMultiPart('CreateCustomAudience', formData);
  }

  UpdateCustomAudience(id: any, SegmentName: any, SegmentDescription: any, file: any) {
    const formData = new FormData();
    formData.append('SegmentName', SegmentName);
    formData.append('SegmentDescription', SegmentDescription || '');
    formData.append('id', id);
    if (file) {
      file.forEach((file: any) => {
        formData.append('files', file);
      });
    }
    return this.applicationApiService.putDataMultiPart('UpdateCustomAudience', formData);
  }
  deleteCustomAudience(id: any) {
    return this.applicationApiService.deleteData("CustomAudience/" + id)
  }

  deleteCsvFiles(body: any) {
    return this.applicationApiService.deleteCsvFiles("CustomAudience/DeleteFile", body);
  }

  uploadCustomAudience(body: any) {
    return this.applicationApiService.postUploadData("CustomAudience/Upload", body)
  }

  uploadProcess(body: any) {
    return this.applicationApiService.postData("CustomAudience/Process", body);
  }
}
