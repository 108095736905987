export const environment = {
    production: false, 
    apiUrl: 'https://rigel-dev.azurewebsites.net/',
    reportUrl: 'https://ad-reporting-api-prod.azurewebsites.net/api/',
    adReport:'https://ad-serving.cheggout.com/',
    // bannerUrl:'https://rigeldev.blob.core.windows.net/banners',
    bannerUrl:'https://cheggoutcore.blr1.cdn.digitaloceanspaces.com/banners/',
    prodHost: 'rigelads.cheggout.com'
  };


  // export const environment = {
  //   production: false, 
  //   apiUrl: 'https://ad-serving-api-prod.azurewebsites.net/api/',
  // };

              
  