<div class="header-background-color">
    <mat-toolbar color="primary">
        <div class="header-content" fxLayout="row" fxFlex="1 1 100%" fxLayoutAlign="space-between center">
            <div>Rigel Ads</div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column">
                    <div class="user-name"> <a mat-button>Welcome {{user}} | Sign Out</a></div>
                    <div> <a mat-button>{{role}}</a> </div>
                </div>
                <div fxLayoutAlign="center center" class="cheggout-logo"><img src="../../assets/cheggout-logo.png"alt=""> </div>
            </div>
        </div>
    </mat-toolbar>
</div>
