import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AccountsService } from 'src/app/services/accounts.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { DashboardControlService } from 'src/app/services/dashboard-control.service';
import { DropdownMapper } from 'src/app/utilities/dropdown-mapping';
import { filterOnTable } from 'src/app/utilities/tablet-filter';

@Component({
  selector: 'app-banner-today-data',
  templateUrl: './banner-today-data.component.html',
  styleUrls: ['./banner-today-data.component.scss']
})
export class BannerTodayDataComponent {
  apiResponse: boolean = false;
  dataSourceClone: any

  

  displayedColumnsbanners: any;
  dataSourceBanners: any;

  accountUser = [];

  constructor(private router: Router,
    private accountsService: AccountsService,
    private authGuardService: AuthGuardService,
    private dropdownMapper: DropdownMapper,
    private dashboardControlService: DashboardControlService) { }

  ngOnInit(): void {
    this.displayedColumnsbanners = [
      "Date",
      "Order ID",
      "Order Name",
      "Banner ID",
      "Banner Name",
      "Scheme",
      "Sum Of Impressions",
      "Sum Of Clicks",
      "CTR"
    ];
    
  
    forkJoin([
      this.dashboardControlService.getDashboardList(
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ),
    ]).subscribe((res: any) => {
      const mappedDataSource: any = [];
      res[0]?.forEach((element: any) => {
        mappedDataSource.push({
          "Date": moment(element.date).format("YYYY-MM-DD"),
          "Order ID":element.orderID,
          "Order Name":element.orderName,
          "Banner ID":element.bannerID,
          "Banner Name":element.bannerName,
          "Scheme": element.scheme,
          "Sum Of Impressions":element.sumOfImpressions,
          "Sum Of Clicks": element.sumOfClicks,
          "CTR":element.ctr,
          data: element
        })
      });
      
      this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
      this.dataSourceBanners = new MatTableDataSource(mappedDataSource);

    });

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceBanners.data = filterOnTable(this.dataSourceClone, filterValue);

  }
}
