import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownMapper {
  // logic to get object from codeId or codeName
  // search single matching item from list of items we got from dropdown's api 
  getDropdownObjectFromCodeOrDesc(codeDesc: any, list: any, key = 'codeId') {
    if (codeDesc) {
      const dynamicKey = key;
      const myObject: any = {};
      myObject[dynamicKey] = codeDesc;
      return list?.find(
        (listItem: any) => listItem['codeId']?.toString() == codeDesc?.toString() ||
          listItem['codeName']?.toString() == codeDesc?.toString() ||
          listItem[key]?.toString() == codeDesc?.toString()
      ) || myObject;
    }
    return null;
  }
  validateDropdownValue(list: any, value: any, key: any) {
    return !(list.find((item: any) => item[key] == value))
  }
}
