<div class="card sub-container">


<div *ngFor="let banner of banners">
    <p style="font-weight: 500;">{{ banner.bannerMain }} - {{ banner.name }}</p>
    <div *ngIf="bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
       
        <div class="img-container">
            <div *ngFor="let response of bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
                <div *ngFor="let image of response.body[0].images">
                    <img [src]="image" alt="Banner Image" style="width: 200px; height: 100px;" />
                    <p style="font-size: 9px;">{{ response.metaData?.campaignName }}</p>
                </div>
            </div>
        </div>
    </div>
</div>


</div>