import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  constructor (
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public data: { statusText: string; errorMessage: string }
    ) {}
  close() {
    this.dialogRef.close(false);
  }
  retry() {
    this.dialogRef.close(true);
  }
}
