import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationApiService {
  baseUrl: string = environment.apiUrl;
  reportBaseUrl: string = environment.reportUrl;
  adReportUrl: string = environment.adReport;

  httpHeaders: HttpHeaders = new HttpHeaders().set("content-type", "application/json");
  httpHeader: HttpHeaders = new HttpHeaders().set("Content-Type", "multipart/form-data");
  constructor(private http: HttpClient) { }

  getData(endpoint: string, queryParam: any) {
    const url = this.baseUrl + endpoint;
    return this.http.get(url, { params: queryParam });
  }

  postData(endpoint: string, body: any) {
    const url = this.baseUrl + endpoint;
    return this.http.post(url, body, { headers: this.httpHeaders });
  }

  putData(endpoint: string, body: any) {
    const url = this.baseUrl + endpoint;
    return this.http.put(url, body, { headers: this.httpHeaders });
  }
  postUploadData(endpoint: string, body: any) {
    const url = this.baseUrl + endpoint;
    return this.http.post(url, body)
  }

  deleteData(endpoint: string) {
    const url = this.baseUrl + endpoint;
    return this.http.delete(url);
  }
  deleteCsvFiles(endpoint: string, body: any) {
    const url = this.baseUrl + endpoint + `${body.id}?fileName=customaudience/${body.fileName}`;
    return this.http.delete(url)
  }

  postDataMultiPart(endpoint: string, body: any,) {
    return this.http.post(`${this.baseUrl}${endpoint}`, body);
  }
  adRequestDataPost(formData: any) {
    const url = this.adReportUrl + 'AdRequest/Post'
    return this.http.post(url, formData)
  }

  putDataMultiPart(endpoint: string, body: any) {
    return this.http.put(`${this.baseUrl}${endpoint}`, body);
  }

  putDataWithParams(endpoint: string, queryParam: any) {
    const url = this.baseUrl + endpoint;
    return this.http.put(url, {}, { headers: this.httpHeaders, params: queryParam });
  }
  getReportData(endpoint: string, queryParam: any) {
    const userId = sessionStorage.getItem('userId');
    var tenantIds: any = sessionStorage.getItem('Tenants');
    tenantIds = tenantIds ? JSON.parse(tenantIds) : [];
    tenantIds = tenantIds?.map((x: any) => x.TenantId);

    const headers = {
      'X-User-ID': userId || '',
      'X-Tenant-ID': tenantIds.join(',') || ''
    };
    const url = this.reportBaseUrl + endpoint;
    return this.http.get(url, { headers: headers, params: queryParam });
  }
  deleteDataWithParams(endpoint: string, body: any) {
    const url = this.baseUrl + endpoint;
    return this.http.delete(url, { body: body });
  }
  getFullListOfCountries() {
    return this.http.get("https://restcountries.com/v3.1/all").pipe(map((re: any) => re.map((r: any) => r.name.common)));
  }
  getCodeTypeByName(codeName: string) {
    return this.getData(`Code/CodeTypeName/${codeName}`, "")
  }

  saveImage(endpoint: string, imageFile: File) {
    const url = this.baseUrl + endpoint;
    const formData = new FormData();
    formData.append('files', imageFile);
    const headers = new HttpHeaders({
      'Accept': '*/*'
    });
    return this.http.post(url, formData, { headers });
  }

  getImage(endpoint: string, params: any) {
    const url = this.baseUrl + endpoint;
    const headers = new HttpHeaders({
      'Accept': '*/*'
    });
    return this.http.get(url, { headers, params, responseType: 'blob' })
    // .pipe(
    // map((blob: Blob) => {
    //   return new Promise<string>((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result as string;
    //       resolve(base64String);
    //     };
    //     reader.readAsDataURL(blob);
    //   });
    // })
    // );
  }

  getDistrictCodes() {
    const headers = new HttpHeaders({
      'Accept': '*/*',
      'XApiKey': '27d34740-c3d4-4938-9260-b5ba3a62922c'
    });
    return this.http.get('https://geo-location.azurewebsites.net/GeoLocation/Cities', { headers });
  }

}
