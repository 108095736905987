import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  canAccess(permissionKey: string): boolean {
    const permissions = sessionStorage.getItem('permission')?.split(',') || [];
    return permissions.includes(permissionKey);
  }

// filterPriorityList(priorityList:any) {
//     if (this.canAccess('Campaign.Priority.Roadblock')) {
//       return priorityList.filter((item:any) => item.codeName !== "Roadblock" );

//     } else {
//       return priorityList;

//     }
//   }
}
