import { Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignsService } from "src/app/services/campaigns.service";
import { RoutesMenu } from "src/app/utilities/menu";
import { filterOnTable } from "src/app/utilities/tablet-filter";

@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.scss']
})
export class CampaignReportComponent {
  dateFrom: string;
  dateTo: string;
  campaignId: number;
  startDate: any;
  endDate: any;
  campaignDta: any
  dataSourceClone: any

  displayedColumns: string[] = [
    "Date",
    "Order ID",
    "Order Name",
    "Campaign ID",
    "Campaign Name",
    "Banner ID",
    "Banner Name",
    "Schedule ID",
    "Daily Requirement",
    "Scheme",
    "Sum Of Impressions",
    "Sum Of Clicks",
    "CTR"
  ]

  includeBannerData: boolean;
  constructor(private campaignsService: CampaignsService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const hasAllParams = params['dateFrom'] && params['dateTo'] && params['includeBannerData'] !== undefined && params['campaignId'];

      if (!hasAllParams) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            dateFrom: params['dateFrom'] || 'null',
            dateTo: params['dateTo'] || 'null',
            includeBannerData: params['includeBannerData'] !== undefined ? params['includeBannerData'] : false,
            campaignId: params['campaignId'] || 0
          },
          queryParamsHandling: 'merge'
        });
      } else {
        this.dateFrom = params['dateFrom'];
        this.dateTo = params['dateTo'];
        this.includeBannerData = params['includeBannerData'] === 'true';
        this.campaignId = +params['campaignId'];
      }

      this.campaignsService.getCampaignData(this.dateFrom, this.dateTo, this.includeBannerData, this.campaignId).subscribe((res: any) => {
        const mappedDataSource: any = [];
        if (res) {
          const arr = Object.keys(res).map(key => res[key]);
          this.campaignDta = res
          arr.forEach((ele: any) => {
            mappedDataSource.push({
              "Date": ele.date,
              "Order ID": ele.orderID,
              "Order Name": ele.orderName,

              "Campaign ID": ele.campaignID,
              "Campaign Name": ele.campaignName,
              "Banner ID": ele.bannerID,
              "Banner Name": ele.bannerName,
              "Schedule ID": ele.scheduleID,

              "Daily Requirement": ele.dailyRequirement,
              "Scheme": ele.scheme,
              "Sum Of Impressions": ele.sumOfImpressions,
              "Sum Of Clicks": ele.sumOfClicks,
              "CTR": ele.ctr,
            })
            this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
            this.campaignDta = new MatTableDataSource(mappedDataSource);
          });
        }
      })
    });
  }
 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.campaignDta.data = filterOnTable(this.dataSourceClone, filterValue);

  }
  backToCampaignHomePage() {
    this.router.navigate([RoutesMenu.deliverCampaignDetail,this.campaignId]);
  }
}
