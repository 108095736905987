import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  _accountId: string;
  _accountsFormData: any;
  constructor(private applicationApiService: ApplicationApiService) {}
  
  getAccountsList() {
    const queryParams = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("Account", queryParams);
  }
  getAccountById(id: string) {
    return this.applicationApiService.getData(`Account/${id}`, "");
  } 
  createAccountList(body: any) {
    return this.applicationApiService.postData("Account", body);
  }
  updateAccountDetailsData(body: any) {
    return this.applicationApiService.putData("Account", body);
  }
  deleteAccountDetailsData(id:number){
    return this.applicationApiService.deleteData("Account/" + id);
  }
  getAccountTypeDropDownList(){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("code/codetypename/Account%20Type", queryParamsDropdown)
  }

  getAccountCategoryDropDownList(){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("code/codetypename/Account%20Category", queryParamsDropdown)
  }
  getAccountBrandSalesDropDown(accountId = ''){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("code", queryParamsDropdown)
  }

  getAccountBrandIAB1DropDown(){
    return this.applicationApiService.getData("code/codetypename/IAB%20Category-I", "")
  }
  getAccountBrandIAB2DropDown(){
    return this.applicationApiService.getData("code/codetypename/IAB%20Category-II", "")
  }
  getAccountBrandRegionDropdown(){
    return this.applicationApiService.getData("code/codetypename/Region","");
  }
  getAccountBrandSalesCategoryDropdown(){
    return this.applicationApiService.getData("code/codetypename/Sales%20Category","")
  }



  get accountFormData(){
    return this._accountsFormData;
   }
   set accountFormData(data){
    this._accountsFormData = data;
   }

}
