import { Component } from '@angular/core';

@Component({
  selector: 'app-forbidden-component',
  templateUrl: './forbidden-component.component.html',
  styleUrls: ['./forbidden-component.component.scss']
})
export class ForbiddenComponentComponent {

}
